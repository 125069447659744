<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard 
  Author:  
  Author URL:  
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/apps/projects">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <!-- USER ROLE FORMS -->
      <div class="vx-col w-full mb-base">
        <country-form></country-form>
      </div>

      <div class="vx-col w-full mb-base">
        <country-list></country-list>
      </div>
    </div>
  </div>
</template>

<script>
import countryForm from "@/views/apps/country/countryForm.vue";
import countryList from "@/views/apps/country/countryList.vue";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ]
    };
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    }
  },
  components: {
    countryForm,
    countryList
  }
};
</script>

<style lang="scss">
</style>
