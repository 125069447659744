import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addCountry(payload) {
    return Api().post(`/v2/country/master/addCountry?organization_id=${organization_id()}`, payload);
  },
  editCountry(payload, id) {
    return Api().post(`/v2/country?organization_id=${organization_id()}`, payload);
  },

  getAllCountry(filters, sort) {
    return Api().get(`/v2/country?organization_id=${organization_id()}&isDashboard=1`, { params: filters })
    // if (filters) {
    //   return Api().get(`/v2/country?organization_id=${organization_id()}&isDashboard=1&page_no=${filters.page ? filters.page : 0}&show_all=${filters.show_all ? filters.show_all : 0}`);
    // } else {
    //   return Api().get(`/v2/country?organization_id=${organization_id()}&isDashboard=1`);
    // }
  },

  addCity(payload) {
    return Api().post(`/v2/city?organization_id=${organization_id()}`, payload);
  },

  updateCity(payload, id) {
    return Api().put(`/v2/city/${id}?organization_id=${organization_id()}`, payload);
  },

  getAllCity(filters, sort) {
    return Api().get(`/v2/city?organization_id=${organization_id()}&page_no=${filters && filters.page ? filters.page : ""}&search=${filters && filters.search ? filters.search : ""}&type=${filters && filters.type ? filters.type : ""}`);
  },

  getCityByCountry(id) {
    return Api().get(`/v2/city/country/${id}?organization_id=${organization_id()}`);
  },
  getCountry() {
    return Api().get(`/v2/country?organization_id=${organization_id()}`);
  }
};
