<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->
<template>
  <div>
    <!-- ADD USER ROLE  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="ADD COUNTRY">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <!-- <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h5 class="mb-0">Organization Name</h5>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        disabled
                        v-validate="'required'"
                        placeholder="Country Name"
                        name="Role Name"
                        v-model="form.country_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Country Name')"
                        >{{ errors.first("Country Name") }}</span
                      >
                    </div>
                  </div>
                </div> -->
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h5 class="mb-0">Country Name*</h5>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required|alpha_spaces'"
                        placeholder="Country Name"
                        name="Country Name"
                        v-model="form.country_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Country Name')"
                        >{{ errors.first("Country Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h5 class="mb-0">Country Code*</h5>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required|alpha'"
                        placeholder="Country Code"
                        name="Country Code"
                        v-model="form.country_code"
                        @input="
                          form.country_code = form.country_code.toUpperCase()
                        "
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Country Code')"
                        >{{ errors.first("Country Code") }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h5 class="mb-0">Country TimeZone*</h5>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div>
                      <multiselect
                        v-model="country_value"
                        track-by="country_id"
                        label="text"
                        placeholder="Select Country"
                        :options="cityOptions"
                        name="Country Name"
                        :searchable="true"
                        :allow-empty="false"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">{{
                          option.text
                        }}</template>
                      </multiselect>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Country Name')"
                        >{{ errors.first("Country Name") }}</span
                      >
                    </div>
                  </div>
                </div> -->
                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-right">
                    <h5 class="mb-0">Status</h5>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <!-- <vs-switch vs-icon-off="2" vs-icon-on="1" v-model="form.department_active" /> -->
                    <b-form-checkbox
                      value="1"
                      unchecked-value="2"
                      v-model="form.country_active"
                      name="check-button"
                      switch
                    ></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
import CountryService from "@/services/countryService.js";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      submitStatus: true,
      User: "Select User",
      country_value: [],
      form: {
        country_name: "",
        country_code: "",
        country_active: "1",
      },
      companies: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    submitForm() {
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          this.submitStatus = true;
          let payload = {
            ...this.form,
          };
          CountryService.addCountry(payload)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.clearForm();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              this.$vs.notify({
                title: "Error!",
                text: error.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
          // if form have no errors
          // alert("form submitted!");
        } else {
          // form have errors
        }
      });
    },

    clearForm: function () {
      Object.keys(this.form).map((item, index) => {
        this.form[item] = null;
      });
      this.form.country_active = "1";
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
